import styled, { css } from "styled-components";
import InternalLink from "../components/InternalLink";

export const Box = styled.div`
    background-color: ${(props) => props.theme.white};
    border-radius: 6px;
    box-shadow: ${(props) =>
        props.shadowless
            ? ""
            : "0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02)"};
    color: ${(props) => props.theme.primaryDark};
    display: block;
    padding: 2rem;
`;

export const Container = styled.div`
    flex-grow: 1;
    margin: 0 auto;
    position: relative;
    width: auto;

    @media screen and (min-width: 1024px) {
        max-width: 960px;
    }

    @media screen and (min-width: 1216px) {
        max-width: 1152px;
    }

    @media screen and (min-width: 1408px) {
        max-width: 1344px;
    }

    
    ${(props) =>
        props.isFluid
        ? "max-width: inherit !important; padding: 0px 10px !important;"
        : ""}
    
    ${(props) => props.large && "max-width: 1600px !important;"}
`;

export const Section = styled.div`
    padding: ${(props) => (props.noPadding ? "3.6rem 0" : "3.6rem 10px")};
    @media (max-width: 768px) {
        padding: 18px 5px;
    }
`;

export const Row = styled.div`
    display: block;
    & + & {
        border-top: solid 1px ${(props) => props.theme.borderColor};
        margin-top: 50px;
        padding-top: 50px;
        @media (max-width: 768px) {
            margin-top: 20px;
            padding-top: 20px;
        }
    }
`;

const isFull980Mixin = css`
    @media (max-width: 980px) {
        width: 100%;
    }
`;

export const Column = styled.div`
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 1.2rem;
    flex: ${(props) => (props.isNarrow ? "none" : "")};
    ${(props) => props.isFull980 && isFull980Mixin}
`;

export const Columns = styled.div`
    margin-left: -1.2rem;
    margin-right: -1.2rem;
    margin-top: -1.2rem;

    :last-child {
        margin-bottom: -1.2rem;
    }

    :not(:last-child) {
        margin-bottom: calc(1.5rem - 1.2rem);
    }

    ${(props) => props.isMultiline && "flex-wrap: wrap;"}

    @media screen and (min-width: 769px), print {
        display: ${(props) => (props.isDesktop ? "block" : "flex")};
    }
`;

export const Content = styled.div`
    padding: 30px 100px;
    min-height: 400px;
    @media (max-width: 1200px) {
        padding: 0;
    }
`;

export const Title = styled.h1`
    color: ${(props) => 
        props.primary ? props.theme.primaryDarkMode : 
        props.color ? props.color
        : props.theme.primaryDark
    };
    font-weight: 600;
    line-height: 1.125;
    margin-bottom: ${(props) => props.marginBottom ?? '20'}px;
    margin-top: ${(props) => props.marginTop ?? '0'}px;
    word-break: break-word;
    ${(props) => props.isCentered && "text-align: center;"}
    span {
        font-weight: inherit;
    }

    ${(props) => {
        if (props.size == "1") {
            return `
      font-size: 4.8rem;
    `;
        } else if (props.size == "2") {
            return `
      font-size: 4rem;
    `;
        } else if (props.size == "3") {
            return `
      font-size: 3.2rem;
    `;
        } else if (props.size == "4") {
            return `
      font-size: 2.4rem;
    `;
        } else if (props.size == "5") {
            return `
      font-size: 2rem;
    `;
        } else if (props.size == "6") {
            return `
      font-size: 1.6rem;
    `;
        } else if (props.size == "7") {
            return `
      font-size: 1.2rem;
    `;
        } else {
            return `
      font-size: 3.2rem;
    `;
        }
    }}
`;

export const Subtitle = styled(Title)`
    color: ${(props) => props.theme.primaryDark};
    font-weight: 400;
    line-height: 1.25;
    font-size: ${(props) => (props.size ? "" : "2rem")};

    &:not(:last-child) {
        margin-bottom: 2.4rem;
    }

    svg {
        width: 16px;
        height: 16px;
        display: inline-block;
        vertical-align: middle;
        color: ${(props) => props.theme.gray};
        margin-top: -4px;
        margin-right: 5px;
    }
`;

export const Heading = styled.div`
    padding-bottom: 20px;
    h2 {
        margin-bottom: 10px;
        text-transform: uppercase;
        font-size: 22px;
        color: ${(props) => props.theme.primaryDark};
        position: relative;
        padding-left: 15px;
        :before {
            content: "";
            width: 10px;
            height: 10px;
            background-color: ${(props) => props.theme.primaryDarkMode};
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -5px;
        }
    }
    h1,
    h3 {
        font-size: 32px;
        font-weight: 900;
        text-wrap: balance;  
        text-transform: uppercase;
        line-height: 1.2;  
        color: ${(props) => props.theme.black};
        padding-bottom: 10px;
        margin-bottom: 10px;
        position: relative;
        // :before {
        //     content: "";
        //     width: 50px;
        //     height: 1px;
        //     background-color: ${(props) => props.theme.primaryDarkMode};
        //     position: absolute;
        //     bottom: 0;
        //     left: 0;
        // }
    }
    p {
        font-size: 15px;
        line-height: 22px;
        color: ${(props) => props.theme.primaryDark};
    }
    h1,
    h2,
    h3,
    p {
        width: 90%;
        max-width: 600px;
    }

    ${(props) => {
        if (props.isCentered) {
            return `
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        h1:before,
        h3:before {
          left: 50%;
          margin-left: -25px;
        }
      `;
        }
    }}

    @media (max-width: 768px) {
        font-size: 16px;
        p {
            font-size: 13px;
            line-height: 18px;
        }
        h3 {
            font-size: 16px;
        }
    }
`;

export const Heading2 = styled.div`
    padding-bottom: 40px;
    color: ${(props) => props.theme.black};
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: center;
    align-items: center;
    .title {
        font-size: 28px;
        text-wrap: balance;  
        text-transform: uppercase;
        line-height: 1.2;  
    }
    .description {
        font-size: 18px;
        text-wrap: balance;    
        line-height: 1.5;
    }

    @media (max-width: 768px) {
        padding-bottom: 20px;
        .title {
            font-size: 22px;
        }
        .description {
            font-size: 14px;
        }
    }
`;

export const BlankState = styled.div`
    text-align: center;
    > div {
        padding: 10%;
        border-radius: 3px;
        > svg {
            color: ${(props) => props.theme.gray};
            width: 60px;
        }
        span {
            svg {
                width: 180px;
            }
        }
    }
    h3 {
        padding: 50px 0 30px;
        font-size: 18px;
        font-weight: bold;
        color: ${(props) => props.theme.gray};
        max-width: 350px;
        margin: auto;
    }
    p {
        color: ${(props) => props.theme.gray};
        max-width: 350px;
        margin: auto;
        font-size: 16px;
    }
    a {
        color: ${(props) => props.theme.link};
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
`;

export const LevelLeft = styled.div`
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-start;
`;
export const LevelRight = styled(LevelLeft)`
    justify-content: flex-end;
`;
export const LevelItem = styled.div`
    align-items: center;
    display: flex;
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;

    h1,
    h2,
    h3,
    h4,
    h5 {
        margin-bottom: 0;
    }
`;

export const Level = styled.div`
    align-items: center;
    justify-content: space-between;
    ${LevelItem} + ${LevelItem} {
        margin-left: 1.2rem;
    }
    ${(props) => {
        if (props.isMobile) {
            return `
        display: flex;

        ${LevelRight},
        ${LevelRight} {
          display: flex;
        }

        ${LevelLeft} + ${LevelRight} {
          margin-top: 0;
        }

        ${LevelItem} + ${LevelItem} {
          margin-bottom: 0;
        }

        ${LevelItem}:not(.is-narrow) {
          flex-grow: 1;
        }
      `;
        } else {
            return `
        @media screen and (max-width: 768px) {
          ${LevelLeft} + ${LevelRight} {
            margin-top: 1.5rem;
          }
          ${LevelItem} + ${LevelItem} {
            margin-top: 1.2rem;
          }
        }
      `;
        }
    }}

    img {
        display: inline-block;
        vertical-align: top;
    }

    @media screen and (min-width: 769px), print {
        display: flex;
        > ${LevelItem}:not(.is-narrow) {
            flex-grow: 1;
        }
    }

    @media screen and (min-width: 769px), print {
        ${LevelItem} + ${LevelItem} {
            margin-left: 1.2rem;
        }
    }

    @media screen and (min-width: 769px), print {
        ${LevelLeft} {
            display: flex;
        }
    }

    ${LevelRight} {
        align-items: center;
        justify-content: flex-end;
    }

    @media screen and (min-width: 769px), print {
        ${LevelRight} {
            display: flex;
        }
    }
`;

export const SimpleCard = styled.div`
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    width: 99%;
    margin: 0.5%;
    figure {
        position: relative;
        padding-top: 65%;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    h3 {
        display: block;
        text-align: center;
        margin: 0;
        padding: 0;
        padding-top: 10px;
        font-weight: bold;
        color: ${(props) => props.theme.primaryDark};
    }
    &:hover h3 {
        color: ${(props) => props.theme.primaryDarkMode};
    }
`;

export const PageContent = styled.div`
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-size: 16px;

    h1,
    h2,
    h3,
    h4,
    h5,
    strong,
    h6 {
        margin: 0;
        padding: 0;
        color: ${(props) => props.theme.primaryDark};
        font-weight: 600;
        line-height: 1.125;
        &:not(:first-child) {
            margin-top: 25px;
        }
    }
    h1 {
        font-size: 2em;
        margin-bottom: 10px;
    }
    h2 {
        font-size: 1.75em;
        margin-bottom: 10px;
    }
    h3 {
        font-size: 1.5em;
        margin-bottom: 8px;
    }
    h4 {
        font-size: 1.25em;
        margin-bottom: 8px;
    }
    h5 {
        font-size: 1.125em;
        margin-bottom: 6px;
    }
    h6 {
        font-size: 1em;
        margin-bottom: 6px;
    }
    p {
        color: ${(props) => props.theme.primaryDark};
        font-size: 1em;
        padding: 0;
        margin: 10px 0;
        a {
            color: ${(props) => props.theme.link};
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }

    p + p {
        margin-top: 10px;
    }

    ul,
    ol {
        list-style-position: outside;
        margin: 20px;
    }
    li {
        font-size: 1em;
    }
    li + li {
        margin-top: 5px;
    }
    ul li {
        list-style: initial;
    }
    ol li {
        list-style-type: number;
    }
    ul ul li {
        list-style-type: circle;
        margin-top: 10px;
    }
    ul ul ul li {
        list-style-type: square;
    }

    figure {
        margin-left: 30px;
        margin-right: 30px;
        text-align: center;
        &:not(:first-child) {
            margin-top: 30px;
        }
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
    figure img {
        display: inline-block;
        max-width: 100%;
    }
    figure figcaption {
        font-style: italic;
        font-size: 12px;
        color: ${(props) => props.theme.gray};
    }
    sub,
    sup {
        font-size: 75%;
    }

    /* Fix height on auto for responsive images on new editor */
    &.blog-post figure.image > img {
        height: auto;
    }

    @media (max-width: 768px) {
        font-size: 12px;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            &:not(:first-child) {
                margin-top: 15px;
            }
        }
    }
`;

export const Spacer = styled.div`
  height: ${(props) => props.height ?? '10'}px;
`;